* {
  font-family: "Avantt-Regular";
}

.golden-bar-below-navbar {
  height: 3px;
  background: linear-gradient(90deg,
      rgb(227, 175, 50) 0%,
      rgb(244, 224, 15) 100%);
}

.jean-logo {
  height: 40px !important;
  width: 25px;
  margin-top: 8px;
  cursor: pointer;
}

.abi-logo {
  width: 100px;
  height: 22px !important;
  margin-top: 12px;
  cursor: pointer;
}

.link-3 {
  margin-bottom: 1rem;
  text-decoration: none;
  justify-content: left !important;
  color: black !important;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  background-color: black;
  /* border-bottom: 5px solid #f2d914; */
}

.left img {
  height: 40px;
}

.middle {
  display: flex;
  margin-left: 30px;
  align-items: center;
}

.right {
  display: grid;
  grid-template-columns: 0.5fr 2fr 0.5fr;
  color: white;
  margin-right: 10px;
}

.hamburger {
  display: none;
  cursor: pointer;
  /* margin-right: 10px; */
}

.submenu {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  width: 100%;
}

.icon {
  position: relative;
  cursor: pointer;
}

.icon-submenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: lightgray;
  right: 1px;
  top: 55px;
  width: 200px;
  padding: 24px 22px;
  z-index: 10;
}

.left {
  display: grid;
  grid-template-columns: 2fr 1fr 5fr;
  margin-left: 1rem;
}

.line {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  height: 20px;
  margin-top: 15px;
}

.line1 {
  width: 1px;
  background-color: white;
  height: 100%;
}

.transformed {
  display: block !important;
}

.middle .link {
  margin-right: 55px;
  text-decoration: none;
  font-size: 14px;
}

.middle .link {
  color: white;
}

.navbar-a {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
}

.middle .link:hover .icon-middle {
  color: goldenrod;
}

.middle .link:hover {
  color: goldenrod;
}

.icon-middle {
  color: white;
  margin-right: 0.3rem;
}

.iconuser {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.icondown {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.icondown:hover {
  color: goldenrod;
}

.middle1 {
  padding: 21px 29px;
  display: none;
  background: linear-gradient(90deg,
      rgb(227, 175, 50) 0%,
      rgb(244, 224, 15) 100%);
  display: none;
}

.icon-middle1 {
  margin-right: 0.5rem;
}

.link2 {
  margin-bottom: 1rem;
  justify-content: left;
  font-size: 1rem;
  color: black;
  text-decoration: none;
}

.link2:hover {
  color: white;
  text-decoration: none;
}

.abi-logo-short {
  display: none;
}

.hamburger:hover {
  color: goldenrod;
}

.ham {
  color: goldenrod;
}

.text {
  margin-right: 1rem;
}

#make-golden {
  color: goldenrod;
}

@media (max-width: 1240px) {
  .profile-photo {
    width: 3rem !important;
  }

  .transformed {
    display: block !important;
  }

  /* .middle1 {
    display: block;
  } */
  .abi-logo {
    display: none;
  }

  .line {
    margin-left: auto;
    height: 20px;
  }

  .abi-logo-short {
    display: block;
    cursor: pointer;
    width: 60px;
    height: 22px !important;
    margin-top: 12px;
  }

  .text {
    display: none;
  }

  .middle {
    display: none;
  }

  .hamburger {
    display: block;
    text-align: center;
  }

  .right {
    flex-direction: column;
    margin-right: 1rem;
  }

  .icondown,
  .iconuser,
  .hamburger {
    margin-right: 0.4rem;
  }
}