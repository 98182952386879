/**************APP FONTS IMPORTS START***************/

@font-face {
  font-family: "Avantt-bold";
  src: url("assets/fonts/Avantt/Avantt-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Avantt-semiBold";
  src: url("assets/fonts/Avantt/Avantt-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Avantt-light";
  src: url("assets/fonts/Avantt/Avantt-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Avantt-regular";
  src: url("assets/fonts/Avantt/Avantt-Regular.ttf") format("truetype");
}

/**************APP FONTS IMPORTS END***************/
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Avantt-regular", sans-serif;
}

:root {
  --primary: #f4e00f;
  --pcolor: #f4e00f;
  --bg: #000;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  font-family: "Avantt-regular", sans-serif;
}

.black {
  color: black !important;
}

.green {
  color: green;
}

.display {
  display: none;
}

.red {
  color: red;
}

.orange {
  color: orange;
}

.no-line-height {
  line-height: unset !important;
}

.name-text {
  font-size: 14px;
  display: inline-block;
}

.margin-left-20 {
  margin-left: 20px;
}

li {
  line-height: 2em;
}

.text-center {
  text-align: center;
}

.golden-text {
  color: #e3af32;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.display-flex {
  display: flex;
}

.nav-logo {
  display: inline-block;
  padding: 0 0.5em;
  border-right: 1px solid var(--pcolor);
  min-height: 1vh;
  line-height: 0.25em !important;
  margin-left: 1.2rem;
  padding-left: 1rem;
}

.nav-container {
  background-color: #000;
  min-height: 3.5rem;
  width: 100%;
  align-items: center !important;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem 0rem 2rem;
}

.nav-wrapper {
  min-height: 5.5rem;
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.nav-wrapper ul li {
  position: relative;
  min-height: unset;
  width: 7.5rem;
  padding: 0 0.3em;
}

.nav-logo {
  display: block;
  height: 1.375rem;
  width: 88px;
}

.home-btn {
  border-bottom: 1px solid white;
  padding: unset !important;
  margin: 0 25px;
}

.request-btn {
  border: 1px solid var(--pcolor);
  background: transparent;
  color: white;
  padding: 5px 13px;
  margin: 0 25px;
}

.name-holder {
  color: white;
  margin: 0 25px;
}

.nav-section {
  /* flex-grow: 1; */
  display: flex;
  justify-content: flex-end;
}

.nav-section ul {
  display: flex;
  align-items: center;
}

.nav-items {
  display: flex;
  /* justify-content: center; */
  /* flex-grow: 10; */
}

.nav-items ul li,
.nav-section ul li {
  list-style: none;
  position: relative;
}

.nav-items ul li a,
.nav-section ul a,
.inner-list li a {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.75rem;
  color: #fff;
  font-weight: 700;
  min-height: 4rem;
  padding: 0.75rem;
}

.nav-items ul li a:hover {
  color: var(--pcolor) !important;
}

.nav-items ul {
  display: flex;
  flex-wrap: wrap;
}

.inner-list {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #000;
  top: 100%;
  left: 0;
  right: auto;
  transform: none;
  width: 15rem;
  height: auto;
  transition: none;
}

.inner-list li a {
  text-transform: capitalize !important;
}

.hidden {
  display: none;
  transition: transform 0.3s;
}

.nav-items ul li:hover .hidden {
  display: block;
}

/*************/
.mobile-menu-btn {
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  align-items: center;
}

.line-1,
.line-2,
.line-3 {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 1.125rem;
  height: 0.0625rem;
  background-color: #fff;
}

.line-1 {
  top: 1.3125rem;
}

.line-2 {
  top: 1.6875rem;
}

.line-3 {
  top: 2.0625rem;
}

.mobile-menu-btn-container {
  display: none;
}

.link-item-header {
  display: none;
}

/****************banner****************/
.banner-wrapper {
  /* background-color: var(--bg); */
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, rgb(23 23 24) 0%, rgb(0 0 0) 100%);

  /* background: linear-gradient(
    to right,
    rgb(27, 35, 48) 0%,
    rgb(13, 17, 24) 100%
  ); */
  color: rgb(255, 255, 255);
  padding: 2.4rem;
  min-height: calc(90vh - 70px);
}

.banner-container {
  flex-grow: 1;
  display: flex;
  max-width: 75rem;
  border-radius: 2rem;
  box-sizing: border-box;
  background: linear-gradient(to right, rgb(23 23 24) 0%, rgb(0 0 0) 100%);
  margin: 0 auto;

  /* background: linear-gradient( to right, rgb(23 23 24) 0%, rgb(0 0 0) 100% ); */
  /* box-shadow: 0 0 #0000, 0 0 #0000, 0 25px 50px -12px rgba(0, 0, 0, 0.25); */
  overflow: hidden;
}

.banner {
  background: linear-gradient(to right, rgb(23 23 24) 0%, rgb(0 0 0) 100%);

  /* background: linear-gradient(
    to right,
    rgb(27, 35, 48) 0%,
    rgb(13, 17, 24) 100%
  ); */
  color: rgb(255, 255, 255);
  width: 63%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-inner {
  max-width: 21rem;
}

.banner-text {
  margin-top: 2rem;
}

.banner h2 {
  color: white;
  font-size: 50px;
  line-height: 55px;
  font-weight: 600;
}

.banner-form {
  width: 37%;
  background-color: rgb(255, 255, 255);
  color: rgb(17, 24, 39);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  /* margin: 45px; */
}

.submit-error {
  padding: 0 16px;
  height: 4.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .banner-form-wrapper {
  padding: 16px;
  max-width: 17rem;
  margin: 0 auto;
  text-align: center;
  min-height: 240px;
} */
.banner-form form {
  display: flex;
  flex-direction: column;
}

.banner-form input,
.input {
  border: 1px solid #d1d3d4;
  outline: none;
  border-radius: 0.25rem;
  padding: 0.75rem 1rem;
  margin-top: 30px;
}

.banner-form .error,
.error {
  color: red;
  font-size: 14px;
  text-align: left;
}

.banner-form button,
.button {
  background: var(--pcolor);
  color: white;
  outline: none;
  border: none;
  border-radius: 18px;
  margin-top: 30px;
  padding: 6px 16px;
  cursor: pointer;
  /*  */
  height: 35px;
}

/********FOOTER*****************/
.footer {
  background-image: url("/public/assets/images/footer_wave.svg");
  /* height: 100px; */
  padding: 2rem 1rem;
  background-color: #f8f8f8;
  background-size: 100% 60px;
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-bottom: 80px;
  margin: auto;
}

.footer-inner {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 90%;
  margin: auto;
  padding: 1rem;
  text-align: left;
}

.footer-bottom {
  font-size: 0.875rem;
  display: flex;
  justify-content: space-around;
}

.footer-items ul li {
  list-style: none;
}

.footer-item {
  display: inline-block;
  padding: 0 0.5em;
  border-right: 1px solid #636466;
  line-height: 1.25em;
}

.footer-link {
  color: #636466;
  text-decoration: none;
  line-height: inherit;
  line-height: 2em;
}

.footer-cr {
  color: #636466;
  line-height: 2em;
}

.JeanLogoLoginImg {
  width: 9rem;
  height: 9rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 1rem;
}


/******Request table***********/
.request-container {
  padding: 0 2rem;
}

.toolbar-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.summary-box {
  height: 4vmax;
  overflow: scroll;
  border: 1px solid black;
  width: 13vmax;
  padding: 3px;
}

#btn_update {
  padding: 10px 20px;
  margin: 5px;
  background-color: white;
  color: black;
}

.btn_update_clicked {
  color: red;
}

.BTN {
  padding: 9px 25px;
  border-radius: 5px;
  background-color: #f5c518;
  /* background-color: red; */
  border: none;
  box-shadow: 0px 2px 0px rgba(128, 128, 128, 0.297);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  cursor: pointer;
  outline: none;
  outline-style: none;
}

.requestDocsButton {
  color: white;
  background-color: #1976d2;
  cursor: not-allowed;
  font-size: 13px;
  font-weight: 400;
}

.BTN:hover {
  background-color: #caa212;
  box-shadow: 0px 4px 3px rgba(128, 128, 128, 0.349);
}

.requestDocsButton:hover {
  background-color: #7e95ac;
}

.requestDocsButton:disabled {
  background-color: #727475;
}

iframe {
  display: none;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

.BTNDISABLE {
  padding: 9px 25px;
  border-radius: 5px;
  background-color: #e0e0e0;
  /* background-color: red; */
  color: grey !important;
  border: none;
  box-shadow: 0px 2px 0px rgba(128, 128, 128, 0.297);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  cursor: not-allowed;
  outline: none;
  outline-style: none;
}

.BTN:disabled {
  padding: 9px 25px;
  border-radius: 5px;
  background-color: #e0e0e0;
  /* background-color: red; */
  color: grey !important;
  border: none;
  box-shadow: 0px 2px 0px rgba(128, 128, 128, 0.297);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  cursor: not-allowed;
  outline: none;
  outline-style: none;
}

.summary-box::-webkit-scrollbar {
  width: 10px;
  scrollbar-color: grey;
}

/*********NEW REQUEST CONTAINER******************/
.box-container {
  max-width: 83rem;
  margin: 0 auto;
  padding: 0 1rem;
}

.new-request-container {
  display: flex;
  margin: 100px 0;
  justify-content: space-between;
  align-items: center;
}

.new-request-banner {
  width: 43%;
}

.new-request-banner-h2 {
  position: sticky;
  font-size: 40px;
}

.new-request-banner-text {
  margin-bottom: 40px;
  font-size: 20px;
  margin-top: 10px;
  font-weight: 600;
}

.new-request-form-container {
  background-color: white;
  border: 3px solid var(--pcolor);
  box-shadow: 0px 0px 10px 5px rgb(0 0 0 / 24%);
  border-radius: 12px;
  /* max-width: 40%; */
  width: 40%;
  margin-right: 35px;
  margin-top: 45px;
}

.new-request-form-wrapper {
  padding: 40px 25px;
}

.new-request-form-label {
  font-size: 12px;
  display: block;
  margin-top: 15px;
  margin-bottom: 5px;
  font-weight: 600;
  text-align: left;
}

.new-request-form-label:nth-child(1) {
  margin-top: 0px;
}

#btn_After_Cancel {
  /* Green */
  background-color: gold;
  border: none;
  color: black;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;

  display: inline-block;
  font-size: 10px;
}

#btn_After_Cancel:hover {
  background-color: red;
}

.new-request-form-input {
  padding: 14px 5px;
  width: 100%;
  margin-top: 5px;
  border-radius: 0;
  border: 1px solid black;
  outline: none;
  line-height: 10px;
}

.new-request-form-input-file {
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.request-type-options {
  padding: 4px 10px !important;
}

.new-request-form-input-file>input[type="file"] {
  display: none;
}

/* .new-request-form-input-file .input-icon */
.new-request-form-input-file>label:hover {
  border-color: hsl(0, 0%, 21%);
  cursor: default;
}

.new-request-form-input-file>label>.input-icon {
  padding-right: 5px;
}

.new-request-form-input-file>label {
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: 50px;
  background: var(--pcolor);
  color: black;
  text-transform: uppercase;
  border: none;
  outline: none;
  padding: 8px 20px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.new-request-form-submit-file {
  width: 95%;
  margin: 0 auto;
  margin-top: 60px;
  border-radius: 25px;
  border: none;
  /* padding: 5px; */
  font-size: 16px;
  height: 100%;
  text-transform: uppercase;
  font-weight: 600;
  background: var(--pcolor);
  color: black;
  display: block;
  box-shadow: 0px 5px 10px 1px rgb(0 0 0 / 24%);
  cursor: pointer;
}

.new-request-form-submit-file-disabled {
  width: 95%;
  margin: 0 auto;
  margin-top: 60px;
  border-radius: 25px;
  border: none;
  /* padding: 10px; */
  font-size: 16px;
  height: 100%;
  text-transform: uppercase;
  font-weight: 600;
  background: var(--pcolor);
  color: black;
  display: block;
  box-shadow: 0px 5px 10px 1px rgb(0 0 0 / 24%);
  cursor: no-drop;
}

.file-download-btn {
  display: flex;
  color: black;
  background-color: var(--pcolor);
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
  box-shadow: 0px 5px 10px 1px rgb(0 0 0 / 24%);
  padding: 8px 15px;
  min-width: 14vmax;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
}

.file-download-btn span {
  margin-left: 5px;
}

.file-download-btn-container {
  display: flex;
  justify-content: space-between;
}

.upload-file-name {
  display: block;
  text-align: center;
}

/**************REQUEST PROCESS *********/
.request-process-container {
  /* display: flex; */
  margin: 10px 25px;
  /* justify-content: space-between; */
  /* align-items: center; */
}

.validation-steps-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.validation-line-green,
.validation-line-grey,
.validation-line-orange,
.validation-line-red {
  display: flex;
  height: 2px;
  width: 15%;
}

.validation-line-green {
  background-color: #22ce7e;
}

.validation-line-grey {
  background-color: #bbbbbb;
}

.validation-line-red {
  background-color: #e16060;
}

.validation-line-orange {
  background-color: #cea322;
}

.validation-circle-green,
.validation-circle-grey,
.validation-circle-orange,
.validation-circle-red {
  width: 3.5vmax;
  height: 3.5vmax;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  font-weight: 600;
  font-size: 25px;
}

.validation-circle-orange {
  background: #cea322;
  border: 1px solid #cea322;
}

.validation-circle-green {
  background: #22ce7e;
  border: 1px solid #22ce7e;
}

.validation-circle-grey {
  background: #bbbbbb;
  border: 1px solid #bbbbbb;
}

.validation-circle-red {
  background: #e16060;
  border: 1px solid #e16060;
}

.ball-1::after,
.ball-2::after,
.ball-3::after {
  position: absolute;
  margin-left: -25px;
}

.ball-1::after {
  content: "Level one validation";
}

.ball-2::after {
  content: "Level Two validation";
}

.ball-3::after {
  content: "SAP validation";
}

.error-container {
  max-width: 90%;
  margin: 0 auto;
  border-radius: 12px;
  box-shadow: 0px 0px 10px 5px rgb(0 0 0 / 24%);
  min-height: 100%;
  margin-top: 40px;
}

.validate-text {
  font-size: 25px;
  text-align: center;
  line-height: 50px;
}

.validate-level-1-text {
  padding-top: 80px;
}

.validate-level-2-text {
  padding-top: 100px;
}

.errors-message-areas,
.alerts-message-area {
  padding: 30px 20px;
}

.error-text level-two-title .error-text,
.alert-text {
  font-size: 21px;
}

.commentError {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.error-messages {
  border: 2px solid #000000;
  min-height: 100%;
  margin-top: 25px;
  margin-bottom: 50px;
  padding: 10px 15px;
}

.error-header {
  display: grid;
  justify-content: space-evenly;
  /* align-content: center; */
  margin: 20px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subHead {
  color: red;
  font-size: 13px;
}

.Comment {
  margin-bottom: 20px;
}

.alerts-message-area {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.main-error-container {
  padding-top: 20px;
}

.main-error-container h4 {
  font-size: 18px;
  margin-bottom: 20px;
}

.main-error {
  margin-left: 30px;
  color: #e16060;
  font-size: 18px;
}

.alerts-message {
  border: 1px solid #0000004d;
  min-height: 100px;
  margin-top: 25px;
  width: 50%;
}

.alerts-message-area form {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-top: 20px;
}

.alerts-message-area form label {
  margin-bottom: 8px;
}

.alerts-message-area form textarea {
  padding: 10px 5px;
  resize: none;
  min-height: 130px;
  border: 1px solid #0000004d;
}

.alerts-message-area form textarea::-webkit-scrollbar {
  display: none;
}

.alerts-message-area form button {
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
  background-color: var(--pcolor);
  color: white;
  border: none;
  outline: none;
  padding: 7px 0px;
  border-radius: 3px;
  font-size: 14px;
}

.level-3-success-conatiner,
.dummy-text {
  display: flex;
  flex-direction: column;
  /* background-color: red; */
  z-index: 9999;
  font-size: 20px;
}

.Loader {
  width: 8vmax;
  margin-left: 45%;
}

.Loader-img {
  width: 8vmax;
}

.Loader-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topLevel3 {
  width: 100%;
  height: 50px;
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid black;
  padding: 0px 20px;
}

.bottomLevel3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-request-btn {
  background-color: var(--pcolor);
  margin-top: 101px;
  width: 38%;
  color: white;
  font-size: 15px;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 8px 0px;
  text-transform: uppercase;
  cursor: pointer;
}

.font-size-35 {
  font-size: 35px;
}

.request-id {
  position: absolute;
  margin-top: 20px;
  display: block;
}

.banner404 {
  margin-top: 105px;
}

.mode-wrapper {
  min-height: 5.5rem;
  display: flex;
  align-items: center;
}

.mode-wrapper ul {
  display: flex;
  flex-wrap: wrap;
}

.mode-wrapper ul li {
  display: flex;
  flex-shrink: 0;
  font-size: 1.5rem;
  color: white;
  /* background-color: ; */
}

.old-request-id {
  font-size: 33px;
  margin-bottom: 10px;
}

/****************MEDIA QUERIES *************/
@media screen and (max-width: 768px) {
  .banner-form-wrapper {
    max-width: unset !important;
  }

  .new-request-form-container {
    width: 90% !important;
  }
}

@media screen and (max-width: 1030px) {
  .display {
    display: block;
  }

  .mobile-menu-btn-container {
    display: block;
    height: 4rem;
  }

  .nav-container {
    padding: 0 1rem;
  }

  .nav-wrapper {
    min-height: unset;
  }

  .nav-items {
    position: absolute;
    top: 0;
    right: -100%;
    width: calc(100% - 3.5rem);
    height: 100vh;
    max-width: 25rem;
    z-index: 1;
    transition: transform 0.3s;
    transition: transform 0.3s 0;
    backface-visibility: hidden;
    overflow: hidden;
    transform: translateX(-100%);
    background: #000;
    justify-content: unset;
  }

  .nav-items ul {
    flex-direction: column;
    width: 100%;
  }

  .nav-items ul li a,
  .menu-tag {
    position: relative;
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
    line-height: 1.5em;
    padding: 0 0.5em;
    display: flex;
    color: #fff;
    /* padding: 1rem; */
    align-items: center;
    text-align: left;
    height: 1px;
    min-height: 3.5rem;
  }

  .menu-items-sm {
    border-bottom: 1px solid #f8f8f8;
  }

  .menu-close-btn-sm {
    position: absolute;
    top: 0;
    right: 0;
    width: 3.5rem;
    height: 3.5rem;
    z-index: 1;
  }

  .menu-close-btn-sm::before,
  .menu-close-btn-sm::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0.0625rem;
    height: 1.25rem;
    background-color: #fff;
  }

  .menu-close-btn-sm::before {
    transform: rotate(45deg);
  }

  .menu-close-btn-sm::after {
    transform: rotate(-45deg);
  }

  .link-item-header {
    display: block;
    display: flex;
    background-color: #231f20;
    flex-shrink: 0;
    font-size: 0.75rem;
  }

  .menu-tag {
    color: #fff;
    border: 0;
  }

  .nav {
    /* position: fixed; */
    top: 0;
    width: 100%;
    z-index: 10;
    /* box-shadow: 0 0 10px rgb(0 0 0 / 10%); */
    transition: transform 0.3s;
    transition: transform 0.3s 0;
    backface-visibility: hidden;
  }

  .banner-container {
    flex-direction: column;
  }

  .banner-form,
  .banner {
    width: 100% !important;
  }

  .banner {
    display: none !important;
  }

  .banner-container {
    margin-top: 55px;
  }

  .footer-inner {
    text-align: center !important;
  }

  .footer-bottom {
    flex-direction: column;
  }

  .footer-bottom {
    font-size: 0.75rem;
  }

  /************* NEW DESIGN CSS************/
  .new-request-banner {
    display: none;
  }

  .new-request-form-container {
    width: 60%;
    margin: 0 auto !important;
  }

  .file-download-btn {
    margin: 0 auto;
    margin-top: 30px;
    min-width: unset !important;
  }

  .file-download-btn-container {
    display: unset !important;
    justify-content: unset !important;
  }

  .box-container {
    margin-top: 85px;
  }

  .request-id {
    position: relative;
    margin-bottom: 10px;
  }
}

.navViewLogo {
  height: 60px;
}

@media only screen and (max-width: 600px) {
  .toolbar-box {
    flex-direction: column;
  }

  .request-container {
    margin-top: 66px;
  }

  .menu-close-btn-sm {
    width: calc(100% - 8.5rem);
  }

  .nav-items ul {
    width: calc(100% - 4.5rem);
  }

  .validation-steps-box {
    justify-content: space-around;
    margin-bottom: 73px;
  }

  .ball-1::after {
    width: 20%;
  }

  .ball-2::after {
    width: 20%;
  }

  .ball-3::after {
    width: 20%;
  }

  .new-request-form-input-file {
    margin: 0px 9px;
  }

  .topLevel3 {
    height: 118px;
  }

  .link-item-header {
    background-color: transparent;
  }

  .menu-close-btn-sm {
    cursor: pointer;
  }

  .nav-items ul {
    padding-left: 0;
  }

  .nav-items ul li a,
  .menu-tag {
    font-size: 1rem;
  }

  .navViewLogo {
    height: 80px;
  }
}

@media only screen and (max-width: 960px) {
  .request-container {
    margin-top: 80px;
  }

  .toolbar-box {
    gap: 5px;
  }

  .link-item-header {
    background-color: transparent;
  }

  .menu-close-btn-sm {
    cursor: pointer;
  }

  .nav-items ul {
    padding-left: 0;
  }

  .nav-items ul li a,
  .menu-tag {
    font-size: 1rem;
  }

  .navViewLogo {
    height: 125px;
  }
}

.proceed-anyway-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.level-two-title {
  margin-top: 16px;
  margin-bottom: 0px;
}

.margin-top-5 {
  margin-top: 5px !important;
}

/* .MuiTableCell-head {
  padding: 4px !important;
}

.journal .MuiTableCell-head {
  background-color: goldenrod;
  color: white;
  border: 1px solid white;
  font-size: 0.75rem !important;
  font-weight: bold !important;
}

.journalDetails .MuiTableCell-head {
  background: none !important;
  color: black;
  border: none;
}

.journal .MuiTableCell-body {
  color: black;
  border: 1px solid white;
  font-size: 0.7rem !important;
}

.journal .MuiTableHead-root {
  border-bottom: 1px solid white;
}

.journal-history-row {
  background-color: '#d3d3d3' !important;
}

.JournalHeader .MuiSelect-select {
  padding: 2px 4px 2px 4px !important;
}

.JournalHeader .MuiSvgIcon-root {
  background-color: white;
  color: black;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  width: 8vw !important
}

.home-button {
  height: 20vh !important;
  width: 25vw !important;
  background: #e3af32 !important;
  color: white !important;
  font-weight: 600 !important;
  font-size: 2rem !important
}

.home-button:hover {
  height: 20vh !important;
  width: 25vw !important;
  background: var(--pcolor) !important;
  color: black !important;
  font-weight: 600 !important;
  font-size: 2rem !important
}
.journal-file-icon {
  background-color: #e5e4e2 !important;
}

.journal-submit-all {
  background: #e3af32 !important;
  color: white !important;
}

.journal-submit-all:hover {
  background: var(--pcolor) !important;
  color: black !important;
}

.journal-comment-sent {
  background: #e3af32 !important;
  color: white !important;
}

.journal-comment-sent:hover {
  background: var(--pcolor) !important;
  color: black !important;
}

.journal-select-approver label {
  color: black !important;
  padding-left: 5px;
}

.journal-select-approver svg {
  border: none !important;
  color: black;
}

.journal-select-approver svg ::after {
  border: none !important;
}

.journal-checked-box svg {
  color: #e3af32 !important;
} */

/* #demo-simple-select:focus {
  border-color: rgb(227, 175, 50)
}


.cognos-select.Mui-selected {
  border-color: rgb(227, 175, 50) !important;
} */

/* .MenuItem.Mui-selected {
  border-color: rgb(227, 175, 50);
  box-shadow: 0 0 0 2px rgba(207, 175, 70, .25) !important
} */

/* .cognos-select.Mui-focused {
  border-color: rgb(227, 175, 50);
  box-shadow: 0 0 0 2px rgba(207, 175, 70, .25) !important
}
.cognos-select .MuiInputBase-input .Mui-focused {
  border-color: rgb(227, 175, 50);
  box-shadow: 0 0 0 2px rgba(207, 175, 70, .25) !important
} */

#cognos-select-label.Mui-focused {
  background-color: white !important;
  color: rgb(33, 37, 41) !important;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 14px !important;
  font-weight: 500 !important;
}

#cognos-select-label {
  background-color: white !important;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
}

.cognos-datepicker>label {
  background-color: white !important;
  color: rgb(33, 37, 41) !important;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.cognos-datepicker>label {
  background-color: white !important;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
}

/* .cognos-datepicker>div {
  border-color: rgb(227, 175, 50) !important;
  box-shadow: 0 0 0 2px rgba(207, 175, 70, .25) !important
}

.cognos-datepicker>div:hover {
  border-color: rgb(227, 175, 50) !important;
  box-shadow: 0 0 0 2px rgba(207, 175, 70, .25) !important
} */

.cognos-datepicker input:focus {
  border: none !important;
  box-shadow: none !important;
}

.cognos-datepicker input {
  padding-top: 20px;
  padding-bottom: 20px;
}

/* .cognos-datepicker > div:focus-within {
  border: none !important;
  box-shadow: none !important;
  background-color: none !important;
} */

.cognos-table-header {
  text-align: center !important;
  font-weight: 700 !important;
  color: #495057;
  /* background-color: rgb(227, 175, 50) !important; */
  background-color: #f1f1f1 !important;
  padding: 4px !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
}

.cognos-wave-button {
  min-width: 10vw !important;
  display: inline-block !important;
  overflow: hidden !important;
  color: #000 !important;
  padding: 0.8em 1em !important;
  text-align: center !important;
  border: 0 !important;
  border-radius: 0.4em !important;
  background-color: #f4e00f !important;
}

.cognos-wave-button:hover {
  min-width: 10vw !important;
  display: inline-block !important;
  overflow: hidden !important;
  color: white !important;
  padding: 0.8em 1em !important;
  text-align: center !important;
  border: 0 !important;
  border-radius: 0.4em !important;
  background-color: #e3af32 !important;
}

.cognos-button {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  text-align: center !important;
  color: black !important;
  width: 12vw;
  height: 6vh;
  background-color: rgb(244, 224, 15) !important;
  padding: 1px !important;
  margin-right: 20px !important;
  /* margin-top: 20px !important; */
}

.cognos-button:hover {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  text-align: center !important;
  color: white !important;
  width: 12vw;
  height: 8vh;
  background-color: rgb(250, 176, 5) !important;
  padding: 1px !important;
  margin-right: 20px !important;
  /* margin-top: 20px !important; */
}

.cognos-table-column {
  text-align: center !important;
  color: #495057;
  background-color: white !important;
  font: 10px !important;
  padding: 2px !important;
  font-family: -apple-system, BlinkMacSystem Font, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
}

.cognos-header-title {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important;
  -webkit-tap-highlight-color: transparent;
  color: rgb(250, 176, 5);
  font-size: 24px !important;
  line-height: 1.55 !important;
  text-decoration: none !important;
  font-weight: 700 !important;
  margin-left: 15vw !important;
}

.cognos-comment {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-tap-highlight-color: transparent;
  color: rgb(250, 176, 5);
  font-size: 18px !important;
  line-height: 1.55 !important;
  text-decoration: none !important;
  font-weight: 700 !important;
  /* text-align: left; */
}

.cognos-datepicker {
  position: "relative";
  background-color: white;
}

.cognos-datepicker:focus {
  border-color: rgb(227, 175, 50);
  box-shadow: 0 0 0 2px rgba(207, 175, 70, 0.25);
}

.cognos-datepicker[aria-expanded="true"] {
  border-color: "rgb(227,175,50)";
  box-shadow: "0 0 0 2px rgba(207,175,70,.25)";
}

.comment-over-flow {
  background: white;
  color: black;
  padding: 3px;
  /* width: 100%; */
  min-height: 50px !important;
  max-height: 120px !important;
  overflow-y: scroll;
  border: 1px solid #ccc;
  /* scroll-behavior: smooth; */
}

.abi-cta {
  box-sizing: border-box;
  margin: 0;
  font-family: "Avantt", sans-serif;
  display: inline-block;
  position: relative;
  font-size: 0.8125rem;
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
  vertical-align: bottom;
  cursor: pointer;
  -webkit-appearance: unset;
  min-width: 10rem;
  min-height: 3rem;
  padding: 0.8rem 2rem;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.abi-cta--round {
  border-radius: 0.25rem;
}

.abi-cta--square {
  border-radius: 0.4rem;
}

.abi-cta--full-width {
  width: 100%;
}

.abi-cta--primary {
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(rgb(255, 255, 255), rgb(0, 0, 0));
  background: #f5e003;
  border: 0;
}

.abi-cta--primary:hover::after {
  transform: translate(0, 0);
  transition: transform 0.75s;
  transition: transform 0.75s 0;
  backface-visibility: hidden;
}

.abi-cta--primary:disabled {
  color: #d1a33c;
  cursor: not-allowed;
}

.abi-cta--primary:disabled:hover::after {
  transform: translate(-66%, 34%);
}

.abi-cta--primary::after {
  position: absolute;
  left: 0;
  content: "";
  background-image: url("./assets/images/primary-button-fill.svg");
  background-size: 60% 250%;
  width: 300%;
  height: 300%;
  background-position: 18% -17%;
  background-repeat: repeat-x;
  bottom: 0;
  transition: transform 1s;
  transition: transform 1s 0;
  backface-visibility: hidden;
  transform: translate(-66%, 34%);
  z-index: -1;
}

.abi-cta--secondary {
  background: #f4e00f;
  border: 1px solid #f4e00f;
}

.abi-cta--secondary:hover {
  border: 1px solid #d1a33c;
}

.abi-cta--secondary:disabled {
  color: #d1a33c;
  cursor: not-allowed;
}

.abi-cta--secondary:disabled:hover {
  border: 1px solid #f4e00f;
}

.abi-cta--tertiary {
  background: #fff;
  border: 1px solid #000;
}

.abi-cta--tertiary:hover {
  background: #f4e00f;
  border: 1px solid #d1a33c;
}

.abi-cta--tertiary:disabled {
  color: #c7c7c7;
  cursor: not-allowed;
}

.abi-cta--tertiary:disabled:hover {
  background: #fff;
  border: 1px solid #000;
}

.abi-cta--cancel {
  background: #fff;
  border: 1px solid #000;
}

.abi-cta--cancel:hover {
  background: #fff;
  border: 1px solid #c7c7c7;
  color: #c7c7c7;
}

.journal-checked-box svg {
  color: #e3af32 !important;
}

.cognos-comment-input input:focus {
  border: none !important;
  box-shadow: none !important;
}

.cognos-admin-search-input input:focus {
  border: none !important;
  box-shadow: none !important;
}

.cognos-admin-search-button {
  color: black !important;
  min-width: "28px";
  min-height: "28px";
  background-color: rgb(250, 176, 5) !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.cognos-admin-search-button:disabled {
  background-color: rgb(241, 243, 245) !important;
}

.cognos-comment-send {
  min-width: "36px";
  min-height: "36px";
  color: black !important;
  background-color: rgb(250, 176, 5) !important;
  margin-left: "9px" !important;
  margin-right: "9px" !important;
}

.cognos-comment-send:disabled {
  background-color: rgb(241, 243, 245) !important;
  margin-left: "9px" !important;
  margin-right: "9px" !important;
}

.cognos-home-card {
  max-width: 36rem;
  word-wrap: break-word;
  background-clip: initial;
  background-color: white;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 32px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center;
  min-width: 20rem;
  min-height: 20rem;
}

.cognos-login-card {
  max-width: 24rem;
  word-wrap: break-word;
  background-clip: initial;
  background-color: white;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 32px !important;
  display: flex !important;
  flex-direction: column !important;
  min-width: 22rem;
}

.golden-text {
  color: #e3af32;
  font-weight: 600;
}

.login-test {
  font-size: 18px;
  font-family: Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI,
    Helvetica Neue, Arial, sans-serif;
}

.login-button {
  width: 100% !important;
  border-radius: 40px;
}

.cognos-comment-primary-text>.MuiListItemText-primary {
  font-size: 12px !important;
  color: black !important;
  font-weight: 500 !important;
}

.cognos-comment-list ul,
li {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.cognos-comment-list {}

.journal th {
  z-index: 999 !important;
}

.journalDetails th {
  z-index: 998 !important;
}

.journalDetails th,
td {
  padding: 3px !important;
}

.cognos-admin-table-head {
  width: "90vw" !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
  align-items: center !important;
  align-content: center !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.cognos-admin-table-header {
  text-align: center !important;
  font-weight: bold !important;
  color: rgb(73, 80, 87) !important;
  font-size: 14px !important;
  padding: 4px !important;
  background-color: lightgrey !important;
}

.cognos-admin-table-cell {
  text-align: center !important;
  color: rgb(73, 80, 87) !important;
  font-size: 12px !important;
  padding: 4px !important;
  /* background-color: white !important; */
}

#reddit-input:focus {
  border: none !important;
}

#reddit-input-label {
  color: black !important;
}

#reddit-input {
  width: 20vw !important;
}

.cognos-admin-table-row {
  cursor: pointer;
}

.cognos-file-download {
  cursor: pointer;
  margin-right: 5px !important;
  padding: 5px;
  background-color: white;
  border: 1px solid var(--pcolor);
}

.cognos-file-download:hover {
  color: white !important;
  background-color: var(--pcolor);
}

.upload-icon-button {
  color: rgb(244, 224, 15) !important;
  cursor: pointer;
}

.upload-icon-button:hover {
  color: rgb(250, 176, 5) !important;
}